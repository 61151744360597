import React from "react"
import { DOWNLOAD_LINK } from "../constants"

const Download = () => {

  if (typeof window !== 'undefined') {
    const win = window.open(DOWNLOAD_LINK, '_self');
    if (win != null) {
      win.focus();
    }
  }

  return (
    <></>
  );
}

export default Download;
